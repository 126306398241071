body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image {
  position: relative;
  height: 100vh;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.content span.img-txt {
  background-color: #333;
  text-transform: uppercase;
  color: #fff;
  padding: 1rem;
  font-size: 1.5rem;
  letter-spacing: 10px;
}

.img-txt {
  text-align: center;
}

h2 {
  letter-spacing: 6px;
  text-transform: uppercase;
  font: 2rem;
  text-align: center;
}

p {
  font: 2rem;
}

.text-box {
  text-align: center;
  /* padding: 3rem 1rem; */
  text-align: justify;
  background-color: #333;
  /* text-transform: uppercase; */
  color: #fff;
  padding: 1rem;
  font-size: 1.9rem;
  letter-spacing: 5px;
}

.text-box1 {
  text-align: center;
  text-align: justify;
}

.theo {
  height: 100rem;
  width: 100%;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.footer {
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-bottom: 25px; */
  /* padding: 3rem 1rem; */
  background-color: #333;
  /* text-transform: uppercase; */
  color: #fff;
  padding: 1rem;
  font-size: 1.9rem;
  letter-spacing: 5px;
}

.counter {
  text-align: center;
}